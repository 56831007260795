import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59061781"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "row",
  style: {"margin-right":"15px"}
}
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "w-40px h-40px rounded-circle bg-gray-lighten d-flex justify-content-center align-items-center" }
const _hoisted_6 = {
  class: "col-9",
  style: {"padding-right":"0px"}
}
const _hoisted_7 = { style: {"list-style-type":"none","margin-bottom":"0px","padding-left":"13px"} }
const _hoisted_8 = { class: "tituloPerfil" }
const _hoisted_9 = { class: "emailPerfil" }
const _hoisted_10 = {
  class: "col-1",
  style: {"padding":"0px 0px 0px 5px","pointer-events":"none"}
}
const _hoisted_11 = {
  class: "d-flex align-items-center d-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_12 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_13 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "cursor-pointer symbol symbol-30px symbol-md-40px",
        "data-kt-menu-trigger": "click",
        "data-kt-menu-attach": "parent",
        "data-kt-menu-placement": "bottom-end",
        "data-kt-menu-flip": "bottom",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showArrow = !_ctx.showArrow))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_ctx.name + _ctx.last), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", null, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.nameComplete + " " + _ctx.lastComplete), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.email), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            (!_ctx.showArrow)
              ? (_openBlock(), _createBlock(_component_inline_svg, {
                  key: 0,
                  src: "media/icons/iconsax/dropWhite.svg",
                  height: "15",
                  width: "15",
                  style: {"vertical-align":"bottom","top":"8px","position":"relative"}
                }))
              : _createCommentVNode("", true),
            (_ctx.showArrow)
              ? (_openBlock(), _createBlock(_component_inline_svg, {
                  key: 1,
                  src: "media/icons/iconsax/dropBlue.svg",
                  style: {"-webkit-transform":"rotate(180deg)","-moz-transform":"rotate(180deg)","-ms-transform":"rotate(180deg)","-o-transform":"rotate(180deg)","transform":"rotate(180deg)","vertical-align":"bottom","top":"8px","position":"relative"},
                  height: "15",
                  width: "15"
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_component_KTUserMenu)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("span", _hoisted_13, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/text/txt001.svg" })
        ])
      ])
    ])
  ]))
}