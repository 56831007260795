
import { defineComponent, computed, ref, onMounted } from "vue";
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import store from "@/store";

export default defineComponent({
  name: "topbar",
  components: {
    KTSearch,
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
  },
  setup (){
    const showArrow = ref<boolean>(false);
    const name = ref<any>();
    const last = ref<any>();
    const nameComplete = ref<any>();
    const lastComplete = ref<any>();
    const email = ref<any>();
    const userInfo = ref<any>();

    onMounted(()=>{
      let userInfoObj:any = localStorage.getItem('userinfo');
      userInfo.value = JSON.parse(userInfoObj);
      name.value=userInfo.value.name.charAt(0).toUpperCase();
      last.value=userInfo.value.last_name.charAt(0).toUpperCase();
      nameComplete.value=userInfo.value.name.charAt(0).toUpperCase() + userInfo.value.name.slice(1);
      lastComplete.value=userInfo.value.last_name.charAt(0).toUpperCase() + userInfo.value.last_name.slice(1);;
      email.value=userInfo.value.email;
    });

    return {
      currentUser: computed(() => store.getters.currentUser),
      showArrow,
      name,
      last,
      userInfo,
      email,
      nameComplete,
      lastComplete
    }
  }
});
