
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
//import MainMenuConfig from "@/core/config/MainMenuConfig";
import MainMenuConfig from "@/core/config/CleanMainMenuConfig";
import store from "@/store/";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    let MainMenuConfig2 = ref<any>([]);
    let newDocMenu = reactive<any>([]);
    const tipoRol = ref<any>();
    const userRole = ref<any>();
    const agencySelected = ref<any>();
    const agencies = ref<any>([]);
    const showAgencies = ref<boolean>(false);
    const router = useRouter();
    const agencyIdSelected = ref<any>();

    /**
     * Menu Validation fuction by permissions
     * @method checkMainMenu
     * @variables CleanMainMenuConfig
     * @returns
     *  CleanMainMenuConfig with validations
     */
    const checkPermissions = async () => {
      if(tipoRol.value != 'Corporativo'){
        MainMenuConfig2.value = [
          {
            heading: "",
            route: "/apps",
            show: true,
            pages: [
              {
                heading: "Inicio",
                route: "/dashboard",
                svgIcon: "",
                fontIcon: "isax-home",
                permission: null,
              },
            ],
          },
          {
            heading: "Empresarial",
            route: "/apps",
            show: true,
            pages: [
              {
                heading: "Agencia",
                route: "/agencies",
                svgIcon: "",
                fontIcon: "isax-shop",
                permission: "Ver listado de agencias",
              },
              {
                heading: "Vehículos",
                route: "/vehicles",
                svgIcon: "",
                fontIcon: "isax-car",
                permission: "Ver listado de vehículos",
              },
              {
                heading: "Apartados",
                route: "/apartados",
                svgIcon: "",
                fontIcon: "isax-receipt-2-1",
                permission: "Ver listado de vehículos", //Cambiar permiso
              },
              {
                heading: "Calendario",
                route: "/calendario",
                svgIcon: "",
                fontIcon: "isax-calendar-1",
                permission: "Ver calendario",
              },
              {
                heading: "Avalúos",
                route: "/avaluos",
                svgIcon: "",
                fontIcon: "isax-receipt-item",
                permission: "Ver listado de avalúos",
              },
              {
                heading: "Avalúos",
                route: "/appraisals",
                svgIcon: "",
                fontIcon: "isax-receipt-item",
                permission: "Ver listado de avalúos en agencia",
              },
              {
                heading: "Avalúos a domicilio",
                route: "/appraisals",
                svgIcon: "",
                fontIcon: "isax-receipt-item",
                permission: "Ver listado de avalúos a domicilio",
              },
              {
                heading: "Leads",
                route: "/leads",
                svgIcon: "",
                fontIcon: "isax-personalcard",
                permission: "Ver listado de leads",
              },
              
            ],
          },
        ];
      }else{
        MainMenuConfig2.value = MainMenuConfig;
      }

      if (tipoRol.value == "Corporativo") {
        MainMenuConfig2.value[2].pages[6] =  {
          heading: "",
          route: "",
          svgIcon: "",
          fontIcon: "",
          permission: "",
        };
        MainMenuConfig2.value[2].pages.slice(6);
      }

      MainMenuConfig2.value.forEach((valuePage, index) => {
        newDocMenu.push(valuePage);
        let unoTienePermiso = false;
        valuePage["pages"].forEach(async (page) => {
          let module = page.route.replace("/", "");
          
          if (module != "dashboard") {
            if (module.includes("agencies")) {
              if (tipoRol.value == "Corporativo") {
                page.route = "/agencies";
              }

              if (tipoRol.value == "Múltiples agencias") {
                page.route = "/agencies/" + agencyIdSelected.value;
                page.permission = "Ver detalle de agencia";
              }
            }
            
            if (module.includes("calendario")) {
              if (tipoRol.value == "Ciudad") {
                page.permission = "Ver detalle de cita a domicilio";
              }else{
                page.permission = "Ver calendario";
              }
            }
            
            //@Todo delete page link
            // if (valuePage[index].heading.includes("Gestión de agencias") && page.permission == "Ver listado de avalúos a domicilio" && tipoRol.value == "Corporativo") {
            //   newDocMenu[index].show = false;
            // }

            if (can(page.permission)) {
              unoTienePermiso = true;
            }
          } else {
            agencyIdSelected.value = await localStorage.getItem("agencyIdSelected");
            
            if(tipoRol.value == 'Múltiples agencias'){
              const { data } = await axios.get( process.env.VUE_APP_API_URL + "/api/get-current-agency");

              if (data.success) {
                if (data.data.id != undefined && data.data.id > 0) {
                  agencyIdSelected.value = data.data.id;
                }
              }

              page.route = "/dashboard/"+agencyIdSelected.value;
            }

            newDocMenu[index].show = true;
          }
        });
        newDocMenu[index].show = unoTienePermiso;
      });
    };

    onMounted(async () => {
      let userRolObj: any = await localStorage.getItem("userRole");
      let agenciesObj: any = await localStorage.getItem("userAgencies");
      agencies.value = JSON.parse(agenciesObj);
      userRole.value = JSON.parse(userRolObj);
      //tipoRol.value = userRole.value.tipo.name;

      // console.log(userRole.value);
      const { data } = await axios.get( process.env.VUE_APP_API_URL + "/api/get-current-agency");
      
      if (data.success) {
        if (data.data.id != undefined && data.data.id > 0) {
          window.localStorage.setItem("agencyIdSelected", data.data.id);
          agencyIdSelected.value = data.data.id;         
          let agenciesObj: any = await localStorage.getItem("userAgencies");
          let agencies = JSON.parse(agenciesObj);
          agencies.forEach((val, ind) => {
            if (val.id == data.data.id) {
              window.localStorage.setItem("agencySelected", val.name);
              agencySelected.value = val.name;
            }
          });
        }
      } else {
        agencySelected.value = await localStorage.getItem("agencySelected");
        agencyIdSelected.value = await localStorage.getItem("agencyIdSelected");
      }
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      await axios.get(process.env.VUE_APP_API_URL + "/api/auth/user").then((response) => {
        if (response.status == 200) {
          tipoRol.value = response.data.data[0].type;
        }
      });
      checkPermissions();
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const can = (permissions) => {
      const Permissions = store.getters.getPermissions;

      if (_.size(Permissions)) {
        if (Array.isArray(permissions)) {
          let flag = false;
          _.map(permissions, (permission) => {
            if (Permissions.indexOf(permission) !== -1) {
              flag = true;
            }
          });
          return flag;
        } else {
          return Permissions.indexOf(permissions) !== -1;
        }
      }
      return false;
    };

    const changeAgencie = async (idAgencie, nameAgency) => {
      let url = process.env.VUE_APP_API_URL + "/api/set-current-agency/" + idAgencie;
      await axios
        .get(url)
        .then(async (response) => {
          if (response.data.success) {
            localStorage.setItem("agencyIdSelected", idAgencie);
            localStorage.setItem("agencySelected", nameAgency);
            agencySelected.value = nameAgency;
            showAgencies.value = !showAgencies.value;
            
            const { data } = await axios.get(
              process.env.VUE_APP_API_URL + "/api/set-current-agency/" + idAgencie
            );
            
            agencyIdSelected.value = idAgencie;

            newDocMenu[0]["route"] = "/dashboard/"+idAgencie;
            newDocMenu[0]["pages"][0]["route"] = "/dashboard/"+idAgencie;

            if(tipoRol.value == 'Múltiples agencias'){
              newDocMenu[1]["pages"][0]["route"] = "/agencies/"+idAgencie;
              console.log("router", router.currentRoute.value.name);
              if(router.currentRoute.value.name == 'dashboard-detail'){
                router.push({
                  name: router.currentRoute.value.name,
                  params: {id: idAgencie}
                });
              }
              if(router.currentRoute.value.name == 'agencies-detail'){
                router.push({
                  name: router.currentRoute.value.name,
                  params: {id: idAgencie}
                });
              }
              if(router.currentRoute.value.name == 'Vehículos'){
                router.push({
                  name: router.currentRoute.value.name,
                  params: {id: idAgencie}
                });
              }
              if(router.currentRoute.value.name == 'Apartados'){
                router.push({
                  name: router.currentRoute.value.name,
                  params: {id: idAgencie}
                });
              }
              if(router.currentRoute.value.name == 'Calendario'){
                router.push({
                  name: router.currentRoute.value.name,
                  params: {id: idAgencie}
                });
              }
              if(router.currentRoute.value.name == 'Avalúos a domicilio'){
                router.push({
                  name: router.currentRoute.value.name,
                  params: {idt: idAgencie}
                });
              }
              if(router.currentRoute.value.name == 'leads-filters' || router.currentRoute.value.name == 'Leads'){
                console.log("router", router.currentRoute.value.name);
                router.push({
                  name: "Leads",
                  params: {id: idAgencie}
                });
              }
              
            }


          }
        })
        .catch((error) => {
          if (error.response) {
            let errors = Object.entries(error.response.data.errors);
            let mensaje = "";
            errors.forEach((value, index) => {
              mensaje += value[1];
            });
            Swal.fire({
              position: "center",
              icon: "error",
              title: mensaje,
              showConfirmButton: true,
            });
          }
        });
    };

    const openAgencias = () => {
      showAgencies.value = !showAgencies.value;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      checkPermissions,
      newDocMenu,
      can,
      tipoRol,
      userRole,
      agencySelected,
      openAgencias,
      agencies,
      showAgencies,
      changeAgencie,
      agencyIdSelected,
      MainMenuConfig2
    };
  },
});
